import React, { useEffect, useState } from "react";
import {
  Col,
  Container,
  Row,
  Button,
  Form,
  InputGroup,
  Spinner,
} from "react-bootstrap";
import "../../assets/css/component.css";
import CarouselLayout from "../../containers/Layouts/CarouselLayout/CarouselLayout";
import CardUnit from "../../components/CardUnit/CardUnit";
import Time from "../../assets/icons/Time";
import Location from "../../assets/icons/Location";
import Coupon from "../../assets/icons/Coupon";
import imageCar from "../../assets/images/category-car.png";
import { useHistory, useParams } from "react-router-dom";
import withAuth from "../../services/withAuthProvider";
import toast from "react-hot-toast";
import { format } from "date-fns";
import { replaceEnter } from "../../utils/string";
import CurrencyInput from "react-currency-input-field";
import KonfirmasiMenangLelang from "../../components/Modals/KonfirmasiMenangLelang";
import Countdown, {
  zeroPad,
  calcTimeDelta,
  formatTimeDelta,
} from "react-countdown";
import ArtworkPending from "./ArtworkPending";
import PageNotFound from "../Errors/PageNotFound";
import Echo from "laravel-echo";
import Pusher from "pusher-js";
import {
  actionsLiveAuction,
  modalSelector,
} from "../../redux/liveAuction.slice";
import { userSelector } from "../../redux/auth.slice";
import { useDispatch, useSelector } from "react-redux";
import ResultLelang from "../../components/Modals/ResultLelang";
import SpecialNIPL from "../../assets/icons/SpecialNIPL";
import Warning from "../../assets/icons/Warning";
import LockOff from "../../assets/icons/lock_off";
import LockOn from "../../assets/icons/lock_on";

const defaultImage =
  "https://st3.depositphotos.com/23594922/31822/v/380/depositphotos_318221368-stock-illustration-missing-picture-page-for-website.jpg?forcejpeg=true";
const initialBid = {
  buyer: {},
  offer_price: 0,
  type: "-",
};
const initialProxy = {
  offer_price: 0,
  nipl: {},
};

const initialDataModal = {
  lot_number: "",
  data_unit: {},
};

export default function LiveAuction() {
  const modal = useSelector(modalSelector);
  // const currentDataUnit = useSelector(currentdataUnitSelector);
  const thisUser = useSelector(userSelector);
  const [SpecialNIPLToggle, setSpecialNIPLToggle] = useState(false);

  const { id } = useParams();
  const { push } = useHistory();
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);
  const [result, setResult] = useState("LOADING");
  const [key, setKey] = useState(1);
  const [color, setColor] = useState("success");
  const [dataModal, setDataModal] = useState({ ...initialDataModal });

  // status
  const [disableBid, setDisabledBid] = useState("off");
  const [blockBid, setBlockBid] = useState("off");
  const [isProcess, setIsProcess] = useState("off");
  const [liveAuction, setLiveAuction] = useState("COMING");
  const [modalResult, setModalResult] = useState(false);
  const [FirstBid, setFirstBid] = useState(false);
  // const [timer, setTimer] = useState({ ...initialTimer });

  // list
  const [data, setData] = useState({});
  const [auction, setAuction] = useState({});
  const [modalWinner, setModalWinner] = useState({});
  const [images, setImages] = useState([]);
  const [nextLots, setNextLots] = useState([]);
  const [biddingList, setBiddingList] = useState([]);
  const [proxyList, setProxyList] = useState([]);

  // bid
  const [offer, setOffer] = useState(150_000_000);
  const [error, setError] = useState("");
  const [transaction_id, setTransactionId] = useState("");
  const [myProxy, setMyProxy] = useState({ ...initialProxy });
  const [highestBid, setHighestBid] = useState({ ...initialBid });
  const [multipleBid, setMultipleBid] = useState(0);
  const [counter, setCounter] = useState(null);

  // Handler
  const changeOffer = (val) => {
    const value = parseInt(val);
    let newError = "";
    if (!value) {
      newError = "Penawaran tidak boleh kosong";
    } else if (value % multipleBid !== 0) {
      newError = `Penawaran harus kelipatan ${multipleBid.toLocaleString()}`;
    } else if (value < highestBid.offer_price) {
      newError = `Penawaran harus lebih besar dari penawaran saat ini`;
    } else {
      newError = "";
    }
    setError(newError);
    setOffer(value);
  };
  const bidHandler = () => {
    if (disableBid === "off") {
      setDisabledBid("on");
    } else {
      setDisabledBid("off");
    }
  };
  const increaseBid = () => setOffer((prev) => parseInt(prev) + multipleBid);
  const decreaseBid = () => {
    const newOffer = parseInt(offer) - multipleBid;
    if (!(newOffer < highestBid)) {
      setOffer(newOffer);
    }
  };

  const toastError = (message) =>
    toast.error(message, {
      id: "error-message",
      duration: 3000,
    });

  // Fetching
  // =================

  const postBid = async () => {
    setIsProcess("on");
    const params = {
      offer: offer,
    };
    // console.log('send', params)
    if (error.length > 0) {
      toastError(error);
    } else {
      const response = await withAuth.postDataWithAuth(
        `/api/v2/auction/${id}/bid`,
        params
      );
      if (response) {
        if (response?.status === 200) {
          toast.success("Penawaran telah dikirim", {
            id: "success-message",
            duration: 3000,
          });
        } else {
          toastError(
            response?.data?.errors?.details || "Gagal melakukan penawaran"
          );
        }
      } else {
        toastError(
          response?.data?.errors?.details || "Gagal terhubung ke server"
        );
      }
      setIsProcess("off");
    }
  };

  // FETCH DATA V2
  const getDetailAuction = async () => {
    const params = {};
    const response = await withAuth.getDataWithAuth(
      `/api/v2/detail-auction/${id}`,
      params
    );
    if (response) {
      if (response?.status === 200) {
        const dataAuction = response.data.data;
        // console.log('getDetailAuction', dataAuction);
        // console.log('ini auction', dataAuction);

        setAuction(dataAuction);
        setLiveAuction(dataAuction?.status);
        // setLiveAuction('STARTED')
        setMultipleBid(dataAuction?.unit_type?.multiple_bid || 0);
        setIsOpen(false);

        if (dataAuction.is_have_nipl === 0) {
          setBlockBid("on");
        } else {
          setBlockBid("off");
        }

        if (dataAuction.current_stock_unit_id !== null && dataAuction?.status !== "CLOSED") {
          getDetailAuctionNextLot(dataAuction?.current_stock_unit_id);
          getDetailAuctionBiddingList(dataAuction?.current_stock_unit_id);
          if (dataAuction?.winner_id === thisUser.id) {
            // console.log('winner')
            getDetailAuctionUnit(dataAuction?.current_stock_unit_id, true);
          } else {
            getDetailAuctionUnit(dataAuction?.current_stock_unit_id, false);
          }
        }
        if(dataAuction?.status === "CLOSED"){
          setIsOpen(false)
        }
        setProxyList(
          dataAuction.proxy_bid.map((el) => ({
            ...el,
            offer_price: parseInt(el.offer_price),
          }))
        );
      } else {
      }
    } else {
      FailedToConnect();
    }
  };
  const getDetailAuctionUnit = async (unit_id, winner) => {
    // console.log('ini data units')
    const imageURL = [];
    const params = {};
    const response = await withAuth.getDataWithAuth(
      `/api/v2/detail-auction/${id}/detail-unit/${unit_id}`,
      params
    );
    if (response) {
      if (response?.status === 200) {
        const dataAuction = response.data.data;
        // console.log('detail unit auc', dataAuction);

        if (dataAuction?.is_special_nipl) setSpecialNIPLToggle(dataAuction);
        else setSpecialNIPLToggle(false);

        setData(dataAuction);
        if (dataAuction.pictures.length > 0) {
          dataAuction.pictures.map((image, i) =>
            imageURL.push({ image: image.image_url })
          );
          setImages(imageURL);
        }
        setIsOpen(false);
        if (winner === true) {
          // console.log('wonn')
          // dispatch(actionsLiveAuction.setCurrentDataUnit({ unit: dataAuction }));
          setModalWinner(dataAuction);
          showModal();
        } else {
          setDataModal((prev) => ({
            ...prev,
            data_unit: dataAuction,
          }));

          if (
            dataAuction?.unit_auction_status === "NOT_SOLD" ||
            dataAuction?.unit_auction_status === "SOLD"
          ) {
            setIsOpen(true);
            setResult("LOADING");
          }

          if (dataAuction?.unit_auction_status === "NOT_SOLD") {
            console.log("unit not sold");
            setResult("TIDAK TERJUAL");
            // setIsOpen(true);
          } else if (dataAuction?.unit_auction_status === "SOLD") {
            console.log("unit sold");
            setResult("TERJUAL");
            // setIsOpen(true);
          }
        }
      } else {
        if (response?.status == 404) {
          // setLiveAuction("NOT_FOUND");
          // if (response.data.message === "Lelang belum dimulai") {
          //     setLiveAuction("PENDING")
          // } else {
          //     setLiveAuction("NOT_FOUND");
          // }
        } else {
          toast.error(response.data.message, {
            id: "error-message",
            duration: 3000,
          });
        }
      }
    } else {
      FailedToConnect();
    }
  };
  const getDetailAuctionNextLot = async (unit_id) => {
    const params = {};
    const response = await withAuth.getDataWithAuth(
      `/api/v2/detail-auction/${id}/next-lot/${unit_id}`,
      params
    );
    if (response)
      if (response?.status === 200) {
        const dataAuction = response.data.data;
        // console.log('ini next lot', dataAuction)
        setNextLots(dataAuction?.filter((a, b) => b <= 3));
      } else {
        FailedToConnect(response.data.message);
      }
    else FailedToConnect();
  };
  const getDetailAuctionBiddingList = async (unit_id) => {
    const params = {};
    const response = await withAuth.getDataWithAuth(
      `/api/v2/detail-auction/${id}/detail-bidder/${unit_id}`,
      params
    );
    // console.log('response', response);

    if (response)
      if (response?.status === 200) {
        const dataAuction = response.data.data;
        // console.log('ini list bid', dataAuction);
        const dataList = dataAuction?.auction_progresses?.map((el) => ({
          ...el,
          offer_price: parseInt(el.offer_price),
        }));
        // console.log('dataList', dataList);
        if (dataList?.length) setFirstBid(true);
        const time_diff = dataAuction?.time_diff || 0;
        // const timestamp = new Date(dataAuction?.timestamp_now).getTime();
        // const blocked = new Date(dataAuction?.blocked_at).getTime();

        // setTimer(prev => ({
        //     timestamp_now: timestamp,
        //     blocked_at: blocked,
        //     different: blocked - timestamp,
        // }));
        const last_price = parseInt(dataAuction?.last_price);
        let nextOffer = parseInt(dataAuction?.last_price);

        if (dataAuction.auction_progresses.length > 0) {
          nextOffer =
            parseInt(last_price) +
            parseInt(dataAuction?.unit_type?.multiple_bid);
        }
        let newData = dataList?.filter((el, idx) => idx !== 0);
        const newHighestBid = {
          buyer: dataList[0]?.buyer || {},
          offer_price: last_price,
          type: dataList?.length > 0 ? dataList[0]?.type : "Harga Dasar",
        };
        // console.log('newHighestBid', newHighestBid);
        newData = checkSameBider(newData, "API");
        localStorage.setItem("listBidder", JSON.stringify(newData));
        localStorage.setItem("highBid", JSON.stringify(newHighestBid));

        setBiddingList(newData);
        setHighestBid(newHighestBid);
        setOffer(nextOffer);
        setKey((prev) => prev + 1);
        setCounter(time_diff);
      } else {
        FailedToConnect(response.data.message);
      }
    else FailedToConnect();
  };

  function FailedToConnect(msg = "Gagal terhubung ke server") {
    toast.error(msg, {
      id: "error-message",
      duration: 3000,
    });
  }

  const handleLatestBid = async (e) => {
    // console.log('==================handleLatestBid===================');
    const dataAuction = e;
    // console.log('ini data auction', dataAuction);
    const time_diff = dataAuction?.time_diff || 0;
    const auction_progress = e?.auction_progress ?? [];
    const highBid = JSON.parse(localStorage.getItem("highBid"));
    let bidList = JSON.parse(localStorage.getItem("listBidder"));
    bidList = checkSameBider(bidList);
    // console.log('bidList', bidList);
    // console.log('highBid', highBid);
    bidList = bidList?.filter(bid => bid?.offer_price !== highBid?.offer_price);

    const found = bidList?.some((v) => v?.id === auction_progress?.id);
    console.log('found', found);
    console.log('auction_progress', auction_progress);

    if (!found) {
      // console.log('# dataAuction', dataAuction);
      const last_price = parseInt(auction_progress?.offer_price);
      let nextOffer =
        parseInt(auction_progress?.offer_price) +
        parseInt(dataAuction?.unit_type?.multiple_bid);
      const currBid = {
        buyer: {
          id: auction_progress?.id,
          buyer_id: auction_progress?.buyer_id,
          user_id: auction_progress?.buyer_user_id,
        },
        user_email: auction_progress?.buyer_email,
        user_name: auction_progress?.buyer_name,
        user_nipl_id: auction_progress?.buyer_nipl_id,
        offer_price: last_price,
        type: auction_progress?.type ? auction_progress?.type : "Harga Dasar",
      };
      // console.log('currBid', currBid);
      if (dataAuction?.source === "BID") {
        const currentHighest = highBid;
        if (currentHighest?.type !== "Harga Dasar") {
          bidList?.unshift(currentHighest);
        }
      }
      // var datas = auction_progress
      // bidList.unshift(datas);
      // const newData = bidList.filter((el, idx) => idx !== 0);
      const uniqueBidList = bidList?.filter((value, index, self) =>
        index === self.findIndex((t) => (
          t.offer_price === value.offer_price
        ))
      );

      localStorage.setItem("listBidder", JSON.stringify(uniqueBidList));
      localStorage.setItem("highBid", JSON.stringify(currBid));

      setBiddingList(uniqueBidList);
      setHighestBid(currBid);
      setOffer(nextOffer);

      setKey((prev) => prev + 1);
      setCounter(time_diff);
    }
  };

  function checkSameBider(bidList, access = "") {
    if (access !== "") {
      console.log("======= API ======= ");
    } else {
      console.log("======= SOCKET ======= ");
    }
    let checkSame = "";
    // console.log('bidList', bidList);
    let newList = bidList?.map((bid) => {
      if (bid.offer_price === checkSame) {
        // console.log('same offer price ', bid);
        return "DOUBLE";
      } else {
        checkSame = bid.offer_price;
        return bid;
      }
    });
    // .filter((bid) => bid !== 'DOUBLE');
    // console.log('newList', newList);
    // return newList;
    return newList?.filter((bid) => bid !== "DOUBLE");
  }

  const showModal = () => {
    dispatch(actionsLiveAuction.setModal({ modal: true }));
    setModalResult(true);
  };
  const closeModal = () => {
    dispatch(actionsLiveAuction.setModal({ modal: false }));
    setModalResult(false);
    getDetailAuction();
  };

  const secondsToTime = (secs) => {
    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);

    return zeroPad(minutes) + ":" + zeroPad(seconds);
  };

  const callData = () => {
    getDetailAuction();
    // setHii(prev => prev + 1);
  };

  useEffect(() => {
    window.addEventListener("focus", callData);

    return () => {
      window.removeEventListener("focus", callData);
    };
  }, []);

  useEffect(() => {
    const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    if (counter > 0) setIsOpen(false);
    if (counter === 0) {
      dispatch(actionsLiveAuction.setCurrentDataUnit({ unit: data }));
      setDataModal((prev) => ({
        ...prev,
        lot_number: auction?.auction_lane_name || "",
      }));
      if (liveAuction !== "PENDING" && liveAuction !== "CLOSED") {
        setResult("LOADING");
        setIsOpen(true);
      }
      // console.log('test')
    } else if (counter <= 3) {
      setBlockBid("on");
      setColor("secondary");
    } else if (counter <= 5) {
      setColor("danger");
    } else if (counter <= 10) {
      setColor("orange");
    } else if (counter <= 15) {
      setColor("warning");
    } else if (counter > 15) {
      setColor("success");
    }
    return () => clearInterval(timer);
  }, [counter]);

  useEffect(() => {
    const bearer = localStorage.getItem("token");
    window.Echo = new Echo({
      broadcaster: "pusher",
      key: process.env.REACT_APP_WS_KEY,
      cluster: process.env.REACT_APP_WS_CLUSTER,
      forceTLS: false,
      authEndpoint: `${process.env.REACT_APP_URL}broadcasting/auth`,
      auth: {
        headers: {
          Authorization: "Bearer " + bearer,
          Accept: "application/json",
        },
      },
    });

    window.Echo.private(`auction.${id}`)
      .listen(`.auction.all`, (e) => {
        // console.log(`.auction.all`);
        getDetailAuction();
      })
      .listen(`.auction.bidder`, (e) => {
        // console.log(`.auction.bidder`);
        handleLatestBid(e).then(() => {
          setIsOpen(false);
        });
      })
      .listen(`.auction.winner_posted`, (e) => {
        // console.log(`.auction.winner_posted`);
        setTransactionId(e?.transaction?.id);
      });

    return () => {
      console.log("stop listen");
      // stop listen
      // window.Echo.private(`auction.${id}`)
      //     .stopListening(`.auction.all`)
      //     .stopListening(`.auction.bidder`)
    };
  }, []);

  useEffect(() => {
    // getAuction();
    localStorage.removeItem("highBid");
    localStorage.removeItem("listBidder");
    getDetailAuction();
  }, []);

  useEffect(() => {
    let blocked = "off";
    if (proxyList?.length > 0) {
      const mine = proxyList[0];
      if (mine.offer_price >= highestBid?.offer_price) {
        blocked = "on";
      }
    }
    setBlockBid(blocked);
    // console.log('SpecialNIPLToggle', SpecialNIPLToggle);
  }, [highestBid.offer_price, proxyList]);

  return (
    <>
      {liveAuction === "STARTED" ? (
        <>
          <div className="page h-100 pb-3">
            <Container>
              <Row>
                <Col md={5}>
                  <div className="box-border mt-15">
                    <div className="unit-slider w-100">
                      <CarouselLayout className="dots-left" dots={true}>
                        {images.length > 0 ? (
                          images.slice(0, 5).map((image, i) => (
                            <div key={"img" + i} style={{ height: "222px" }}>
                              {/* <div className='with-background detail-unit-slider' style={(image.image) ? { background: `url(${image?.image})`, height: '222px' } : { height: '222px' }}> */}
                              <img
                                className="with-background detail-unit-slider"
                                src={image.image}
                                style={{
                                  height: "auto",
                                  width: "100%",
                                  objectFit: "contain",
                                }}
                              />
                            </div>
                          ))
                        ) : (
                          <div style={{ height: "222px" }}>
                            <div
                              className="with-background detail-unit-slider"
                              style={{
                                background: defaultImage
                                  ? `url(${defaultImage})`
                                  : "",
                                height: "100%",
                                width: "100%",
                                objectFit: "contain",
                                minHeight: 200,
                              }}
                            ></div>
                          </div>
                        )}
                      </CarouselLayout>

                      <div className="grade-desktop d-none d-md-block  ">
                        <p className="nomor-lot ">
                          Grade
                          <span>{data?.unit_inspection?.grade || "-"}</span>
                        </p>
                      </div>
                      {/* mobile view */}
                      <div className="d-block d-md-none position-absolute top-0 end-0">
                        <div className="badge-lot">
                          <span className="nomor-lot me-2">
                            Lot{" "}
                            {auction?.auction_lane_name && data?.lot_number
                              ? auction?.auction_lane_name + data?.lot_number
                              : "-"}
                          </span>
                          <span className="nomor-lot">
                            Grade {data?.unit_inspection?.grade || "-"}
                          </span>

                          {SpecialNIPLToggle.is_special_nipl === "true" && (
                            <span className="nomor-lot nipl">
                              <span className="icon-nipl">
                                <SpecialNIPL />
                              </span>
                              Special NIPL
                            </span>
                          )}
                        </div>
                      </div>

                      {/* desktop view */}
                      <div className="grade-unit-auction d-none d-md-absolute">
                        Grade
                        <br />
                        <span className="grade-auction">
                          {data?.unit_inspection?.grade || "-"}
                        </span>
                      </div>
                    </div>
                    <div className="d-none d-md-block">
                      <h2 className="unit-name-auction">
                        {data?.unit_name || ""}
                      </h2>
                      <span className="unit-title">Highlight</span>
                      <div className="unit-highlight">
                        {data?.tags?.length > 0
                          ? data?.tags.map((row, i) => (
                            <span key={"tag" + i}>{row.name}</span>
                          ))
                          : "-"}
                      </div>

                      <span className="unit-title">Detail Kendaraan</span>
                      <div className="bidder-data">
                        <div
                          className="d-flex flex-wrap"
                          style={{ gap: "8px" }}
                        >
                          {data?.stock_informations?.length > 0
                            ? data?.stock_informations
                              ?.filter((el) => el.value !== "")
                              .map(({ label, value }, idx) => (
                                <div
                                  className="informasi-unit-auction"
                                  key={`information-${idx + 1}`}
                                >
                                  <span className="title">{label}</span>
                                  <span className="value">
                                      {value || "-"}
                                    </span>
                                </div>
                              ))
                            : ""}
                          {data?.stock_documents?.length > 0
                            ? data?.stock_documents
                              ?.filter((el) => el.value !== "")
                              .map(({ label, value }, idx) => (
                                <div
                                  className="informasi-unit-auction"
                                  key={`information-${idx + 1}`}
                                >
                                  <span className="title">{label}</span>
                                  <span className="value">
                                      {value || "-"}
                                    </span>
                                </div>
                              ))
                            : ""}
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md={7}>
                  <div className="mt-15">
                    <div className="d-none d-md-block">
                      <div className="badge-lot">
                        <span className="nomor-lot">
                          Lot{" "}
                          {auction?.auction_lane_name && data?.lot_number
                            ? auction?.auction_lane_name + data?.lot_number
                            : "-"}
                        </span>
                        {SpecialNIPLToggle.is_special_nipl === "true" && (
                          <span className="nomor-lot nipl">
                            <span className="icon-nipl">
                              <SpecialNIPL />
                            </span>
                            Special NIPL
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                      <span className="unit-title">Harga Dasar</span>
                      <span className="d-inline d-md-none unit-title">
                        Bidder
                      </span>
                    </div>
                    <div className="group">
                      <span className="unit-price" style={{ flex: "1" }}>
                        Rp{" "}
                        {data?.base_price !== undefined
                          ? data?.base_price?.toLocaleString()
                          : "0"}
                      </span>
                      <span
                        className="count-customer"
                        style={{ textAlign: "right" }}
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M20 22H4V20C4 18.6739 4.52678 17.4021 5.46447 16.4645C6.40215 15.5268 7.67392 15 9 15H15C16.3261 15 17.5979 15.5268 18.5355 16.4645C19.4732 17.4021 20 18.6739 20 20V22ZM12 13C11.2121 13 10.4319 12.8448 9.7039 12.5433C8.97595 12.2417 8.31451 11.7998 7.75736 11.2426C7.20021 10.6855 6.75825 10.0241 6.45672 9.2961C6.15519 8.56815 6 7.78793 6 7C6 6.21207 6.15519 5.43185 6.45672 4.7039C6.75825 3.97595 7.20021 3.31451 7.75736 2.75736C8.31451 2.20021 8.97595 1.75825 9.7039 1.45672C10.4319 1.15519 11.2121 1 12 1C13.5913 1 15.1174 1.63214 16.2426 2.75736C17.3679 3.88258 18 5.4087 18 7C18 8.5913 17.3679 10.1174 16.2426 11.2426C15.1174 12.3679 13.5913 13 12 13Z"
                            fill="#646566"
                          />
                        </svg>
                        {auction?.nipls_count} Orang
                      </span>
                    </div>

                    <div className="bidder-list" style={{ marginTop: "20px" }}>
                      <div className="header flex-column flex-md-row">
                        <span className="title" style={{ textAlign: "left" }}>
                          Harga Penawaran Saat Ini
                        </span>
                        <span className="timer text-center">
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9.99935 18.3337C14.6017 18.3337 18.3327 14.6027 18.3327 10.0003C18.3327 5.39795 14.6017 1.66699 9.99935 1.66699C5.39698 1.66699 1.66602 5.39795 1.66602 10.0003C1.66602 14.6027 5.39698 18.3337 9.99935 18.3337Z"
                              stroke="#C20102"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M10 5V10L13.3333 11.6667"
                              stroke="#C20102"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                          {/* {zeroPad(time.time.m)}:{zeroPad(time.time.s)} */}
                          {secondsToTime(counter)}
                          {/* <Countdown
                                                        key={key}
                                                        // now={() => setTimeout(() =>
                                                        //     (timer.timestamp_now + 1000)
                                                        //     , 1000)}
                                                        date={timer.timestamp_now + timer.different}
                                                        // onComplete={() => setIsOpen(true)}
                                                        renderer={showWinner}
                                                    /> */}
                        </span>
                      </div>
                      {/* ini untuk desktop view */}
                      <div className="d-none d-md-block px-4 pb-4">
                        <div
                          className="highest-bid border"
                          style={{ borderRadius: "0.5rem" }}
                        >
                          <span className="bid-value">
                            Rp{" "}
                            {highestBid?.offer_price
                              ? highestBid?.offer_price?.toLocaleString()
                              : "-"}
                          </span>
                          <span className="bidder">
                            {highestBid?.type === "Online Bidder" &&
                            highestBid?.buyer?.user_id === thisUser.id
                              ? "Saya"
                              : highestBid?.type || "-"}
                          </span>
                        </div>
                      </div>
                      {/* ini untuk mobile view */}
                      <div className="d-block d-md-none px-4 pb-4">
                        <div
                          className="highest-bid d-flex border"
                          style={{ borderRadius: "0.5rem" }}
                        >
                          <span
                            className="bid-value"
                            style={{ fontSize: "20px" }}
                          >
                            Rp{" "}
                            {highestBid?.offer_price
                              ? highestBid?.offer_price?.toLocaleString()
                              : "-"}
                          </span>
                          <span className="bidder" style={{ fontSize: "16px" }}>
                            {highestBid?.type === "Online Bidder" &&
                            highestBid?.buyer?.user_id === thisUser.id
                              ? "Saya"
                              : highestBid?.type || "-"}
                          </span>
                        </div>
                      </div>
                      <div className="bidder-data d-none d-md-block">
                        {biddingList?.length > 0
                          ? biddingList
                          ?.filter(bid => bid?.offer_price !== highestBid?.offer_price)
                          ?.map((bid, index) => (
                            <div
                              className={`bidder-item ${
                                bid?.type === "Online Bidder" &&
                                bid?.buyer?.user_id === thisUser.id &&
                                "active"
                              }`}
                              key={`bid-${index}`}
                            >
                                <span className="bid-value">
                                  Rp {bid?.offer_price?.toLocaleString()}
                                </span>
                              <span className="bidder">
                                  {bid?.type === "Online Bidder" &&
                                  bid?.buyer?.user_id === thisUser.id
                                    ? "Saya"
                                    : bid?.type || "-"}
                                </span>
                            </div>
                          ))
                          : !FirstBid && (
                          <div className="bidder-item text-center font-sm text-secondary">
                                <span className="mx-auto">
                                  Belum ada yang melakukan penawaran
                                </span>
                          </div>
                        )}
                      </div>
                    </div>

                    <div
                      className={`box-border proxy-bid ${
                        proxyList.length > 0 ? "d-block" : "d-none"
                      }`}
                      style={{ marginTop: "20px" }}
                    >
                      <span className="title-header">Proxy Bid</span>
                      {proxyList.length > 0
                        ? proxyList.map((proxy, iddx) => (
                            <div className="group" key={`proxy-${iddx}`}>
                              <span
                                style={{
                                  padding: "5px",
                                  background: "#F9E6E6",
                                  borderRadius: "50%",
                                }}
                              >
                                <svg
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M9.08353 1.75L17.3327 2.92917L18.511 11.1792L10.851 18.8392C10.6948 18.9954 10.4828 19.0832 10.2619 19.0832C10.0409 19.0832 9.82897 18.9954 9.6727 18.8392L1.4227 10.5892C1.26647 10.4329 1.17871 10.221 1.17871 10C1.17871 9.77903 1.26647 9.56711 1.4227 9.41083L9.08353 1.75ZM11.4402 8.82167C11.595 8.97641 11.7788 9.09914 11.981 9.18287C12.1832 9.26659 12.4 9.30967 12.6188 9.30963C12.8377 9.30959 13.0544 9.26644 13.2566 9.18264C13.4588 9.09885 13.6425 8.97605 13.7973 8.82125C13.952 8.66645 14.0748 8.4827 14.1585 8.28047C14.2422 8.07824 14.2853 7.8615 14.2852 7.64262C14.2852 7.42375 14.2421 7.20702 14.1583 7.00482C14.0745 6.80262 13.9517 6.61891 13.7969 6.46417C13.6421 6.30943 13.4583 6.18669 13.2561 6.10296C13.0539 6.01924 12.8371 5.97617 12.6182 5.97621C12.1762 5.97629 11.7523 6.15196 11.4398 6.46458C11.1273 6.77721 10.9517 7.20117 10.9518 7.64321C10.9519 8.08525 11.1276 8.50915 11.4402 8.82167Z"
                                    fill="#C20102"
                                  />
                                </svg>
                              </span>
                              <span
                                className="no-nipl"
                                style={{ flex: "1" }}
                              >{`${proxy?.nipl?.nipl_code} - ${proxy?.nipl?.nipl}`}</span>
                              <span className="bidder" style={{ flex: "1" }}>
                                Rp{" "}
                                {parseInt(proxy?.offer_price).toLocaleString()}
                              </span>
                            </div>
                          ))
                        : ""}
                    </div>

                    <div
                      className="d-none d-md-block box-border form-bid"
                      style={{ marginTop: "20px", overflow: "hidden" }}
                    >
                      {SpecialNIPLToggle &&
                        SpecialNIPLToggle?.is_special_nipl === "true" && (
                          <>
                            {SpecialNIPLToggle?.remaining_nipls <
                              SpecialNIPLToggle?.number_of_nipl &&  
                               SpecialNIPLToggle?.used_nipls_this_stock_unit === 0 && (
                                <div className="alert-nipl">
                                  <Warning color="#FF4D4D" /> Jumlah NIPL Anda
                                  tidak cukup untuk melakukan penawaran
                                </div>
                              )}
                          </>
                        )}
                      <div className="d-flex tw-justify-between m-2">
                        <span className="title-header">Tawar</span>
                        {SpecialNIPLToggle.is_special_nipl === "true" && (
                          <div className="coupon-header d-flex">
                            <Coupon />
                            <p className="m-0">
                              NIPL anda:{" "}
                              <span>{SpecialNIPLToggle.remaining_nipls}</span>
                            </p>
                          </div>
                        )}
                      </div>
                      <div className="group">
                        <div style={{ flex: "1" }}>
                          <InputGroup>
                            <InputGroup.Text id="basic-addon1">
                              Rp
                            </InputGroup.Text>
                            <CurrencyInput
                              id="hargaTawar"
                              name="offer"
                              className="form-control"
                              placeholder="Masukan penawaran"
                              disabled={
                                disableBid === "off" || blockBid === "on"
                              }
                              value={offer}
                              prefix="Rp "
                              groupSeparator=","
                              decimalSeparator="."
                              readOnly
                              allowDecimals={false}
                              onValueChange={(value, name) =>
                                changeOffer(value)
                              }
                            />
                          </InputGroup>
                          <small className="text-primary">{error}</small>
                        </div>
                        {/* <div>
                            <ButtonGroup className="mb-2">
                                <Button className='plus-minus' disabled={disableBid === "on" || blockBid === 'on'} onClick={decreaseBid}>
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M4.16602 9.16699H15.8327V10.8337H4.16602V9.16699Z" fill="#092540" />
                                    </svg>
                                </Button>
                                <Button className='plus-minus' disabled={disableBid === "on" || blockBid === 'on'} onClick={increaseBid}>
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.16602 9.16699V4.16699H10.8327V9.16699H15.8327V10.8337H10.8327V15.8337H9.16602V10.8337H4.16602V9.16699H9.16602Z" fill="#092540" />
                                    </svg>
                                </Button>
                            </ButtonGroup>
                        </div> */}
                      </div>
                      <div
                        className="group flex-center tes"
                        style={{ marginTop: "10px" }}
                      >
                        <div
                          className="d-flex"
                          style={{
                            flexWrap: "wrap",
                            alignItems: "center",
                            textAlign: "left",
                            justifyContent: "space-between",
                            maxWidth: "530px",
                            width: "100%",
                          }}
                        >
                          <div className="d-flex align-items-center">
                            <div className={`d-flex align-items-center `}>
                              <LockOn active={disableBid} />
                              <span
                                className="font-sm ml-1 me-4"
                                style={{
                                  color: disableBid === "off" ? "#ff0000" : "",
                                }}
                              >
                                Nonaktif
                              </span>
                            </div>
                            <Form.Check
                              className="ms-1"
                              type="switch"
                              id="custom-switch"
                              checked={disableBid === "on" ? true : false}
                              // value={disableBid}
                              onClick={bidHandler}
                            />
                            <div className={`d-flex align-items-center`}>
                              <LockOff active={disableBid} />
                              <span
                                className="font-sm ml-1 me-4"
                                style={{
                                  color: disableBid === "on" ? "#2AB95E" : "",
                                }}
                              >
                                Aktif
                              </span>
                            </div>
                          </div>
                          {SpecialNIPLToggle.is_special_nipl === "true" && (
                            <div
                              className="coupon-footer d-flex"
                              // style={{
                              //   borderLeft: '1px solid #cbcbcb',
                              // }}
                              style={{ width: "170px", minWidth: "unset" }}
                            >
                              <Coupon />
                              <p className="m-0">
                                <span>
                                  {SpecialNIPLToggle.number_of_nipl} NIPL
                                </span>{" "}
                                untuk menawar
                              </p>
                            </div>
                          )}
                        </div>
                        <Button
                          className={`${
                            disableBid === "off" ? "bid-off" : "bid-on"
                          }`}
                          disabled={
                            disableBid === "off" ||
                            blockBid === "on" ||
                            isProcess === "on"
                          }
                          onClick={postBid}
                          variant={color}
                          style={{ width: "160px" }}
                        >
                          {isProcess === "on" ? (
                            <Spinner animation="border" />
                          ) : (
                            "Tawar"
                          )}
                        </Button>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>

            {/* ini hanya tampil di mobile */}
            <Container className="d-block d-md-none">
              <div className="box-lelang p-3" style={{ margin: "20px 0" }}>
                <span className="unit-title mt-0">Highlight</span>
                <div className="unit-highlight">
                  {data?.tags?.length > 0
                    ? data?.tags.map((row, i) => (
                      <span key={"tag" + i}>{row.name}</span>
                    ))
                    : "-"}
                </div>

                <span className="unit-title">Detail Kendaraan</span>
                <div className="bidder-data">
                  <div className="d-flex flex-wrap" style={{ gap: "8px" }}>
                    {data?.stock_informations?.length > 0
                      ? data?.stock_informations
                        ?.filter((el) => el.value !== "")
                        .map(({ label, value }, idx) => (
                          <div
                            className="informasi-unit-auction"
                            key={`information-${idx + 1}`}
                          >
                            <span className="title">{label}</span>
                            <span className="value">{value || "-"}</span>
                          </div>
                        ))
                      : ""}
                    {data?.stock_documents?.length > 0
                      ? data?.stock_documents
                        ?.filter((el) => el.value !== "")
                        .map(({ label, value }, idx) => (
                          <div
                            className="informasi-unit-auction"
                            key={`information-${idx + 1}`}
                          >
                            <span className="title">{label}</span>
                            <span className="value">{value || "-"}</span>
                          </div>
                        ))
                      : ""}
                  </div>
                </div>
              </div>
            </Container>
            <Container>
              <Row>
                <Col md="12">
                  <div
                    className="box-lelang d-none d-md-block"
                    style={
                      data?.current_stock_unit?.unit_type?.banner_url
                        ? {
                          marginTop: "20px",
                          background: `url(${data?.current_stock_unit?.unit_type?.banner_url})`,
                        }
                        : {
                          marginTop: "20px",
                          backgroundImage: `url(${imageCar})`,
                        }
                    }
                  >
                    <div className="group">
                      <div
                        style={{
                          flex: "1",
                          paddingLeft: "20px",
                          paddingTop: "10px",
                        }}
                      >
                        <span className="lelang-title">
                          {auction?.name || "-"}
                        </span>
                        <div
                          className="lelang-date"
                          style={{ color: "#1E1E1F" }}
                        >
                          {auction?.date_start && auction?.time_start
                            ? format(
                              new Date(
                                `${auction.date_start} ${auction.time_start}`
                              ),
                              "dd MMM yyyy"
                            )
                            : "-"}
                        </div>
                      </div>
                      <div
                        style={{
                          flex: "1",
                          paddingLeft: "20px",
                          paddingTop: "10px",
                        }}
                      >
                        <div className="lelang-time">
                          <Time />{" "}
                          <span>
                            {auction?.date_start && auction?.time_start
                              ? format(
                                new Date(
                                  `${auction.date_start} ${auction.time_start}`
                                ),
                                "HH:mm"
                              )
                              : "-"}{" "}
                            -{" "}
                            {auction?.date_start && auction?.time_finish
                              ? format(
                                new Date(
                                  `${auction?.date_start} ${auction?.time_finish}`
                                ),
                                "HH:mm"
                              )
                              : "-"}{" "}
                            WIB
                          </span>
                        </div>
                        <div className="lelang-location">
                          <Location />{" "}
                          <span>{auction?.location_name || "-"}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="box-lelang d-flex d-md-none"
                    style={{ marginTop: "20px" }}
                  >
                    <div className="d-flex flex-column flex-md-column">
                      <div
                        style={{
                          flex: "1",
                          paddingLeft: "20px",
                          paddingTop: "10px",
                        }}
                      >
                        <span className="lelang-title">
                          {auction?.name || "-"}
                        </span>
                        <div
                          className="lelang-date"
                          style={{ color: "#1E1E1F" }}
                        >
                          {auction?.date_start && auction?.time_start
                            ? format(
                              new Date(
                                `${auction.date_start} ${auction.time_start}`
                              ),
                              "dd MMM yyyy"
                            )
                            : "-"}
                        </div>
                      </div>
                      <div
                        style={{
                          flex: "1",
                          paddingLeft: "20px",
                          paddingTop: "10px",
                        }}
                      >
                        <div className="lelang-time">
                          <Time />{" "}
                          <span>
                            {auction?.date_start && auction?.time_start
                              ? format(
                                new Date(
                                  `${auction.date_start} ${auction.time_start}`
                                ),
                                "HH:mm"
                              )
                              : "-"}{" "}
                            -{" "}
                            {auction?.date_start && auction?.time_finish
                              ? format(
                                new Date(
                                  `${auction?.date_start} ${auction?.time_finish}`
                                ),
                                "HH:mm"
                              )
                              : "-"}{" "}
                            WIB
                          </span>
                        </div>
                        <div className="lelang-location">
                          <Location />{" "}
                          <span>{auction?.location_name || "-"}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
            <Container>
              <div className="produk-terkait w-100 overflow-auto">
                <div>
                  <h3>Lot Selanjutnya</h3>
                </div>
                <div className="d-flex" style={{ gap: "1rem" }}>
                  {nextLots?.length > 0 ? (
                    nextLots.map((item, idx) => {
                      const year = item?.unit_informations?.find(
                        (unit_information) => unit_information.label === "Tahun"
                      );

                      const odometer = item?.unit_informations?.find(
                        (unit_information) =>
                          unit_information.label === "Odometer"
                      );

                      const transmisi = item?.unit_informations?.find(
                        (unit_information) =>
                          unit_information.label === "Transmisi"
                      );

                      const fuel = item?.unit_informations?.find(
                        (unit_information) =>
                          unit_information.label === "Bahan Bakar"
                      );

                      const validityPeriod = item?.unit_documents?.find(
                        (unit_document) => unit_document.label === "STNK"
                      );

                      const unitDetail = {
                        police_number: item?.police_number,
                        year: year?.value,
                        odometer: odometer?.value,
                        transmisi: transmisi?.value,
                        fuel: fuel?.value,
                        validityPeriod: validityPeriod?.additional_value,
                      };
                      return (
                        <div
                          className="w-25"
                          key={"lot" + idx}
                          style={{ minWidth: "200px" }}
                        >
                          <CardUnit
                            // nama="2017 MATICA PRINTING MACHINE S7000E"
                            timeLocation={true}
                            lot_number={item?.lot_number || "-"}
                            price={item?.base_price}
                            tags={item?.tags || []}
                            nama={item?.unit_name}
                            location={item?.auction?.location_name}
                            auction={item?.auction}
                            pictures={item?.pictures}
                            grade={item?.unit_inspection?.grade}
                            unitType={item?.unit_type?.name}
                            unitDetail={unitDetail}
                          ></CardUnit>
                        </div>
                      );
                    })
                  ) : (
                    <Col md="2">Tidak ada Lot selanjutnya</Col>
                  )}
                </div>
              </div>
            </Container>

            {/* tampil saat mobile */}
            <div
              className="d-block d-md-none position-fixed w-100 bottom-0 box-border form-bid"
              style={{ marginTop: "20px", overflow: "hidden", zIndex: 10 }}
            >
              {SpecialNIPLToggle.is_special_nipl === "true" && (
                <>
                  {SpecialNIPLToggle.remaining_nipls <
                    SpecialNIPLToggle.number_of_nipl && (
                      <div
                        className="alert-nipl"
                        style={{
                          top: "15px",
                          marginTop: -"35px",
                        }}
                      >
                        <Warning color="#FF4D4D" /> Jumlah NIPL Anda tidak cukup
                        untuk melakukan penawaran
                      </div>
                    )}
                </>
              )}
              <div className="d-flex tw-justify-between m-2">
                <span className="title-header">Tawar</span>{" "}
                {SpecialNIPLToggle.is_special_nipl === "true" && (
                  <div className="coupon-header d-flex">
                    <Coupon />
                    <p className="m-0">
                      NIPL anda:{" "}
                      <span>{SpecialNIPLToggle.remaining_nipls}</span>
                    </p>
                  </div>
                )}
              </div>
              <div className="group">
                <div style={{ flex: "1" }}>
                  <InputGroup>
                    <InputGroup.Text id="basic-addon1">Rp</InputGroup.Text>
                    <CurrencyInput
                      id="hargaTawar"
                      name="offer"
                      className="form-control"
                      placeholder="Masukan penawaran"
                      disabled={disableBid === "off" || blockBid === "on"}
                      value={offer}
                      prefix="Rp "
                      groupSeparator=","
                      decimalSeparator="."
                      readOnly
                      allowDecimals={false}
                      onValueChange={(value, name) => changeOffer(value)}
                    />
                  </InputGroup>
                  <small className="text-primary">{error}</small>
                </div>
              </div>
              <div
                className="group align-items-center"
                style={{ marginTop: "10px" }}
              >
                <div
                  className="d-flex"
                  style={{
                    flexWrap: "wrap",
                    alignItems: "center",
                    textAlign: "right",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <div className="d-flex align-items-center">
                    <Form.Check
                      className="ms-1 me-2"
                      type="switch"
                      id="custom-switch"
                      checked={disableBid === "on" ? true : false}
                      // value={disableBid}
                      onClick={bidHandler}
                    />
                    <div className={`d-flex align-items-center ml-2`}>
                      {disableBid === "on" ? (
                        <>
                          {" "}
                          <LockOff active={disableBid} />
                          <span
                            className="font-sm ml-1 "
                            style={{
                              color: disableBid === "on" ? "#2AB95E" : "",
                            }}
                          >
                            Aktif
                          </span>
                        </>
                      ) : (
                        <>
                          <LockOn active={disableBid} />
                          <span
                            className="font-sm ml-1 "
                            style={{
                              color: disableBid === "off" ? "#ff0000" : "",
                            }}
                          >
                            Nonaktif
                          </span>
                        </>
                      )}
                    </div>
                  </div>{" "}
                  {SpecialNIPLToggle.is_special_nipl === "true" && (
                    <div className="coupon-footer d-flex">
                      <Coupon />

                      <p
                        className="m-0"
                        style={{
                          borderRight: "none",
                        }}
                      >
                        <span>{SpecialNIPLToggle.number_of_nipl} NIPL</span>{" "}
                        untuk menawar
                      </p>
                    </div>
                  )}
                </div>

                <Button
                  className={`${disableBid === "off" ? "bid-off" : "bid-on"}`}
                  disabled={
                    disableBid === "off" ||
                    blockBid === "on" ||
                    isProcess === "on"
                  }
                  onClick={postBid}
                  variant={color}
                >
                  {isProcess === "on" ? (
                    <Spinner animation="border" />
                  ) : (
                    "Tawar"
                  )}
                </Button>
              </div>
            </div>
          </div>
          {/* {modalResult ? (
                        <KonfirmasiMenangLelang continue={closeModal} checkout={() => push(`/pengaturan/transaksi/pembelian/${transaction_id}`)} data={modalWinner} />
                    ) : ""}
                    {
                        isOpen ? (
                            <ResultLelang status={result} dataAuction={dataModal?.data_unit} />
                        ) : ''
                    } */}
        </>
      ) : (
        ""
      )}
      {liveAuction === "PENDING" ||
      liveAuction === "COMING" ||
      liveAuction === "CLOSED" ? (
        <ArtworkPending status={liveAuction} />
      ) : (
        ""
      )}
      {liveAuction === "NOT_FOUND" ? <PageNotFound></PageNotFound> : ""}

      {modalResult ? (
        <KonfirmasiMenangLelang
          continue={closeModal}
          checkout={() =>
            push(`/pengaturan/transaksi/pembelian/${transaction_id}`)
          }
          data={modalWinner}
        />
      ) : (
        ""
      )}
      {isOpen ? (
        <ResultLelang status={result} dataAuction={dataModal?.data_unit} />
      ) : (
        ""
      )}
    </>
  );
}
